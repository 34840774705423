import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FA963C',
    },
    secondary: {
      main: '#FFF',
    },
    error: {
      main: '#FFF',
    },
    background: {
      default: '#3C3C3C',
    },
  },
});

export default theme;
