import React, { FormEvent, useEffect, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ChangeEvent, MouseEvent } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Magic } from 'magic-sdk';
import { CircularProgress } from '@material-ui/core';
import {
  gql,
  useMutation
} from "@apollo/client";

const GENERATE_LICNSE = gql`
  mutation createLicense($macAddress: String!, $emailAddress: String!) {
    createLicense(data:
      {
        macAddress: $macAddress,
        emailAddress: $emailAddress
      }
    )
    {licenseKey} 
  }
`

function copyToClipboard(textToCopy: string) {
  try {
    navigator.clipboard.writeText(textToCopy);
  }
  catch {

  }

}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 50
  },
  license: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 30
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    '& label.Mui-focused': {
      color: '#FA963C',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FA963C',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FA963C',
      },
      '&:hover fieldset': {
        borderColor: '#FA963C',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FA963C',
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    "&:disabled": {
      backgroundColor: 'rgba(250, 150, 60, 0.6)',
      color: 'rgba(0, 0, 0, 0.87);'
    }
  },
  input: {
    color: "#FFF"
  },
  inputLabel: {
    color: "#FA963C"
  },
  success: {
    backgroundColor: "#FA963C"
  },
}));

function isEmailAddress(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}


function isMacAddress(str: string | undefined) {
  var macAddress = /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/;
  if (typeof (str) !== 'string')
    return false
  return macAddress.test(str);
}

const m = new Magic('pk_live_178A5FDC37246F9E');

async function authStatus(cb: Function) {
  try {
    const status = await m.user.isLoggedIn();
    cb(status);
  } catch {
    return undefined
  }
}

async function login(email: string, cb: Function) {
  try {
    await m.auth.loginWithMagicLink({ email: email });
    cb(true);
  } catch {
    // Handle errors if required!
  }
}

async function getEmail(cb: Function) {
  try {
    const { email } = await m.user.getMetadata();
    cb(email);
  } catch {

  }
}

const ActivationButton = ({ macAddress, onClick, children }: { macAddress: string, onClick: any, children: string }) => {
  const classes = useStyles();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = () => {
    //enqueueSnackbar('Activation key copied to clipboard!', { variant: 'success' });
  };

  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      color="primary"
      className={classes.submit}
      onClick={onClick(showSnackbar)}
      disabled={!isMacAddress(macAddress)}
    >{children}</Button>
  );
}

export default function SignIn() {
  const classes = useStyles();

  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const [emailAddress, setEmailAddress] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [licenseKey, setLicenseKey] = useState('');
  const [generateLicense, { data, loading }] = useMutation(GENERATE_LICNSE);

  useEffect(() => {
    if (data !== undefined && loading === false) {
      console.log(data)
      const { createLicense: { licenseKey } } = data
      setLicenseKey(licenseKey)
      copyToClipboard(licenseKey)
    }
  }, [data, loading])

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  }

  const handleClick = (cb: Function) => {
    return () => {
      try {
        generateLicense({ variables: { emailAddress, macAddress } })
        cb()
      }
      catch (e) {

      }
    }

  }

  const handleSignIn = (event: MouseEvent) => {
    try {
      login(emailAddress, setIsLoggedIn);
    }
    catch (e) {

    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setMacAddress(value);
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setEmailAddress(value);
  }

  useEffect(() => {
    authStatus(setIsLoggedIn);
    getEmail(setEmailAddress);
  }, [isLoggedIn])

  return (
    <SnackbarProvider
      classes={{
        variantSuccess: classes.success,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <LockOutlinedIcon className={classes.avatar}></LockOutlinedIcon>
          <Typography component="h1" variant="h5" className={classes.avatar}>
            DDP Activator
          </Typography>
          {isLoggedIn == undefined &&
            <CircularProgress color="primary" />
          }
          {isLoggedIn &&
            <Fragment>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="mac"
                  label="MAC address"
                  name="mac-address"
                  placeholder="00:00:00:00:00:00"
                  autoFocus
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  value={macAddress}
                  onChange={handleChange}
                />
                <ActivationButton macAddress={macAddress} onClick={handleClick}>
                  Activate
                </ActivationButton>
              </form>
              <Typography component="h5" variant="h5" className={classes.license}>
                {licenseKey}
              </Typography>
            </Fragment>
          }
          {isLoggedIn === false &&
            <Fragment>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  autoFocus
                  InputProps={{
                    className: classes.input
                  }}
                  InputLabelProps={{
                    className: classes.inputLabel
                  }}
                  value={emailAddress}
                  onChange={handleEmailChange}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSignIn}
                  disabled={!isEmailAddress(emailAddress)}
                >Sign in</Button>
              </form>
            </Fragment>
          }

        </div>
      </Container >
    </SnackbarProvider>
  );
}